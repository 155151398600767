import { useQuery } from 'react-query';
import { TOAST_STATUS, toastActions } from '@leagueplatform/toast-messages';
import { contentServerFetch } from '../api/content-server-fetch';

const CONTENT_SERVER_ASSET_QUERY_KEY = 'CONTENT_SERVER_ASSET_QUERY_KEY';

export const useGetAssetAsBlob = (contentId: string) =>
  useQuery<string, Error>(
    [CONTENT_SERVER_ASSET_QUERY_KEY, contentId],
    async () => {
      const res = await contentServerFetch(contentId);

      const blob = await res.blob();

      return URL.createObjectURL(blob);
    },
    {
      onError: () => {
        toastActions.add({
          type: TOAST_STATUS.ERROR,
          textId: 'STR_ERROR_STATE_BODY_API',
        });
      },
    },
  );
