import { StandaloneAuth } from '@leagueplatform/auth-standalone';
import { getContentUrl } from '@leagueplatform/league-content-api';
import {
  ErrorContext,
  captureAppError,
  combineErrorContexts,
} from '@leagueplatform/observability';
import { SENTRY_CONSTANTS } from 'common/constants';

/**
 * Function to make a fetch call to the Content Server to retrieve a specific asset
 * @param id : Id of content from content server
 * @param options : Any extra options to apply to the request. It can include method, body, etc.
 */

export const contentServerFetch = async (
  id: string,
  options: RequestInit = {},
  errorContext: ErrorContext = {},
): Promise<Response> => {
  const url = getContentUrl(id);

  const token = await StandaloneAuth.client.getTokenSilently();

  const requestOptions: RequestInit = {
    method: 'GET',
    ...options,
    headers: {
      Authorization: `Bearer ${token}`,
      ...(options.headers || {}),
    },
  };

  const promise = fetch(url, requestOptions);

  const response = await promise;

  // capture error responses for observability
  if (!response.ok) {
    const body = await response.clone().text();
    captureAppError(
      new Error(body),
      combineErrorContexts([
        {
          errorName: `XO Health Content Server Api Response Error - ${id}`,
          context: {
            'Response Info': {
              ok: response.ok,
              redirected: response.redirected,
              response: body,
              status: response.status,
              type: response.type,
              url: response.url,
            },
          },
          ownershipTeam: SENTRY_CONSTANTS.OWNERSHIP_TEAM,
          tags: { requestUrl: response.url },
        },
        errorContext,
      ]),
    );
    throw new Error(
      `Error Response Code ${response?.status}: ${await response.text()}`,
    );
  }

  return promise;
};
